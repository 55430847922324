import React from "react";
import { Link } from "react-router-dom";

export default function Menu(props) {
  return (
    <div
      className={props.toggle === true ? "menu animation1" : "menu animation2"}
    >
      <Link to="/rooms">
        <div className="menu_item">Rooms & Cloud 9</div>
      </Link>
      <Link to="/gallery">
        <div className="menu_item">Gallery</div>
      </Link>
      <Link to="/bar">
        <div className="menu_item">Restaurant & Bar</div>
      </Link>
      <Link to="/team">
        <div className="menu_item">Our Team</div>
      </Link>

      <Link to="/contact">
        <div className="menu_item">Contact Us</div>
      </Link>
      <Link to="/shortlet">
        <div className="menu_item">Shortlet</div>
      </Link>
    </div>
  );
}
