import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Policy() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />

      <section className="policy_section1">
        <div className="policy_text1">Hotel Policy</div>
      </section>

      <section className="policy_text2">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In sed arcu
        ipsum risus enim fames. Arcu in massa eu habitasse. Senectus quam ut
        magna amet, suspendisse. Felis eu condimentum faucibus ac urna, bibendum
        elit rutrum in. Cursus leo vel nunc semper enim egestas nulla
        pellentesque. Id sagittis neque curabitur netus gravida eu. Mi vitae ut
        congue morbi purus lacinia. Sed convallis fermentum, integer et nisl,
        ipsum. Pharetra, sit pharetra tincidunt aenean tellus sit cursus ornare
        lectus. Mauris ut laoreet tristique interdum morbi cursus cras
        vestibulum amet. Aliquet metus ullamcorper purus dictumst nibh. Ipsum
        volutpat tellus, nascetur eu ridiculus est, facilisis imperdiet. Cursus
        suspendisse vestibulum, gravida nulla arcu tortor sed. Adipiscing ut
        nisi, turpis diam proin erat et elit.
        <br />
        <br />
        Cras placerat fermentum nulla nibh lorem in massa. Quisque porttitor
        amet ut quis lorem. Purus nibh enim a elit eu, ullamcorper neque vitae
        eu. Elementum egestas ultricies tincidunt consectetur metus. Justo risus
        proin nisl aliquam neque, nibh eu consectetur. Elementum ac quis diam
        potenti commodo sed sed dictum. Scelerisque viverra eget sit viverra
        amet risus et mi. Magna ut quis nec, rhoncus, vestibulum metus est
        gravida. Duis pellentesque ac urna eu gravida. Egestas metus, vitae arcu
        quis eget etiam.
        <br />
        <br />
        Mauris enim sit mi tellus. Pretium, gravida morbi vestibulum quis congue
        feugiat nunc. Enim, nisl id diam tellus. Tortor a mauris leo consequat
        turpis risus malesuada. Non adipiscing aliquet id erat ut interdum
        netus. Id faucibus at aliquet non at. Pretium donec dictum purus sapien
        amet, risus etiam aliquam. Sit cras adipiscing in tortor viverra massa
        aliquet. Amet vitae aliquam diam aliquet. Diam dui orci pellentesque
        nulla. Amet mauris at diam nisi. Hendrerit nec quis consectetur quisque
        amet platea sit vitae turpis. Faucibus orci enim vitae facilisi
        sollicitudin nec imperdiet. Diam tincidunt varius leo sed nunc
        phasellus. Amet amet, non consectetur justo, auctor sed imperdiet. In
        mauris sed donec ipsum, congue. Velit dolor lorem adipiscing vehicula ac
        neque ut integer.
      </section>
      <Footer />
    </div>
  );
}
