import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer_inner">
        <div>
          <div>
            <Link to="/">
              <img
                src="./images/logo2.svg"
                className="logo_footer"
                alt="logo"
              />
            </Link>
          </div>
          <div className="footer_text1">3 Erimionren Close Off,</div>
          <div className="footer_text1">
            Osamagioghomwenwi Street, Benin City.
          </div>
          <div className="footer_text1">08153139048, 08124406864</div>
          <div className="footer_text1">contact@bakerversesuites.com</div>
          <div className="footer_text2">
            &copy; 2022 Baker Industries Limited. All rights Reserved.
          </div>
        </div>
        <div className="footer-up">
          <Link to="/">
            <div className="footer_text1">Home</div>
          </Link>

          <Link to="/rooms">
            <div className="footer_text1">Rooms & Cloud 9</div>
          </Link>

          <Link to="/gallery">
            <div className="footer_text1">Gallery</div>
          </Link>
        </div>
        <div className="footer-up">
          <Link to="/bar">
            <div className="footer_text1">Restaurant & Bar</div>
          </Link>
          <Link to="/team">
            <div className="footer_text1">Our Team</div>
          </Link>

          <Link to="/contact">
            <div className="footer_text1">Contact Us</div>
          </Link>
        </div>
        <div className="footer-up">
          <Link to="/terms">
            <div className="footer_text1"> Terms & Conditions</div>
          </Link>

          <Link to="/privacy">
            <div className="footer_text1">Privacy Policy</div>
          </Link>
        </div>
      </div>
    </div>
  );
}
