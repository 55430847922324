import React, { useState } from "react";
import Menu from "./Menu";
import { Link } from "react-router-dom";

export default function Header() {
  const [toggle, setToggle] = useState(false);
  return (
    <div>
      <div className="header">
        <div className="inner_header">
          <div>
            <Link to="/">
              <img src="./images/logo2.svg" alt="logo" />
            </Link>
          </div>
          <div className="header_box">
            <div className="ham_flex">
              {toggle === false ? (
                <img
                  src="./images/ham.svg"
                  onClick={() => setToggle(!toggle)}
                  className="ham"
                  alt="ham"
                />
              ) : (
                <img
                  src="./images/close.svg"
                  onClick={() => setToggle(!toggle)}
                  className="ham"
                  alt="ham"
                />
              )}

              <div
                onClick={() => setToggle(!toggle)}
                className="head_text1 menu-none"
                style={{ cursor: "pointer" }}
              >
                Menu
              </div>
            </div>
            {/*  <div className="head_text2">Find a Room</div> */}
          </div>
        </div>
      </div>

      <Menu toggle={toggle} />
    </div>
  );
}
