import React, { useEffect, useRef } from "react";
import Header from "../components/Header";
import Map, {
  Marker,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
} from "react-map-gl";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import { NotificationManager } from "react-notifications";
import Footer from "../components/Footer";
/* eslint-disable import/no-webpack-loader-syntax */
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export default function Contact() {
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();

  const createReq = async (evt) => {
    evt.preventDefault();
    try {
      const cred = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        message: messageRef.current.value,
      };
      const res = await axios.post(`https://bakerhomes.io/api/v1/mail`, cred);
      if (res.data.code === 200) {
        NotificationManager.success("Request Submitted Successfull", "Success");

        nameRef.current.value = "";
        emailRef.current.value = "";
        phoneRef.current.value = "";
        messageRef.current.value = "";
      }
    } catch (err) {
      console.log(err);
      NotificationManager.error("An error ocuured", "Error");
    }
  };
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <section className="map2">
        <Map
          initialViewState={{
            latitude: 6.3345989,
            longitude: 5.6032433,
            zoom: 12,
            bearing: 0,
            pitch: 0,
          }}
          mapboxAccessToken="pk.eyJ1Ijoiam9zaDQzMjQiLCJhIjoiY2tiemoyYmN2MGJ6ODJ2bXJmM25pbjN1dSJ9.veWU3GwQOzzf0OSAA_TRNg"
          width="100%"
          height="100%"
          transitionDuration="200"
          mapStyle="mapbox://styles/mapbox/streets-v9"
        >
          <FullscreenControl position="top-left" />
          <NavigationControl position="top-left" />
          <ScaleControl />
          <Marker
            latitude="6.4362819"
            longitude="3.5369253"
            offsetLeft={-3.5 * 12}
            offsetTop={-7 * 12}
          ></Marker>
        </Map>
      </section>
      <section className="contact_section2">
        <div className="contact_box1">
          <div className="contact_text1 ">We would love to hear from you</div>
          <div className="contact_text2">Mobile Number </div>
          <div className="contact_text3">08124584337</div>
          <div className="contact_text2">Mobile Number 2</div>
          <div className="contact_text3">08153139048</div>
          <div className="contact_text2">Email </div>
          <div className="contact_text3">contact@bakerversesuites.com</div>

          <div className="contact_text2">Opening-Closing hours</div>
          <div className="contact_text3">07:00 AM - 12 PM</div>
        </div>
        <div className="contact_box2">
          <form onSubmit={createReq} className="form">
            <div className="contact_box">
              <div className="contact_label">What’s your Full Name ? *</div>
              <input
                className="contact_input"
                placeholder="Enter your full name"
                ref={nameRef}
              />
            </div>

            <div className="contact_box">
              <div className="contact_label">How can we contact you ? *</div>
              <input
                className="contact_input"
                placeholder="Enter your email address"
                ref={emailRef}
                required
              />
            </div>

            <div className="contact_box">
              <div className="contact_label">What’s your Phone Number ? *</div>
              <input
                className="contact_input"
                placeholder="Enter your phone number"
                ref={phoneRef}
                required
              />
            </div>

            <div className="contact_box">
              <div className="contact_label">Write your Message *</div>
              <textarea
                ref={messageRef}
                required
                className="contact_textarea"
              ></textarea>
            </div>

            <button className="contact_but">Send message</button>
          </form>
        </div>
      </section>
      <Footer />
    </div>
  );
}
