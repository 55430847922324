import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Shortlet() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [galState, setState] = useState("3");

  const changeGal = (state) => {
    setState(state);
  };
  return (
    <div>
      <Header />
      <section>
        <div className="gal_text1">Shortlet Apartments</div>
      </section>

      <section>
        <div className="gal_bar">
          <div
            className={galState === "2" ? "active" : "none"}
            style={{ cursor: "pointer" }}
            onClick={() => changeGal("2")}
          >
            2-Bedroom apartment
          </div>
          <div
            className={galState === "3" ? "active" : "none"}
            style={{ cursor: "pointer" }}
            onClick={() => changeGal("3")}
          >
            3-Bedroom
          </div>
          <div
            className={galState === "1" ? "active" : "none"}
            style={{ cursor: "pointer" }}
            onClick={() => changeGal("1")}
          >
            1-Bedroom
          </div>
        </div>

        {galState === "2" ? (
          <div className="gal_flex">
            <img className="galimg" src="./images/s1.png" alt="gal1" />
            <img className="galimg" src="./images/s2.png" alt="gal1" />
            <img className="galimg" src="./images/s3.png" alt="gal1" />
            <img className="galimg" src="./images/s4.png" alt="gal1" />
            <img className="galimg" src="./images/s5.png" alt="gal1" />
            <img className="galimg" src="./images/s6.png" alt="gal1" />
          </div>
        ) : null}

        {galState === "3" ? (
          <div className="gal_flex">
            <img className="galimg" src="./images/s2.png" alt="gal1" />
            <img className="galimg" src="./images/s4.png" alt="gal1" />
            <img className="galimg" src="./images/s1.png" alt="gal1" />
            <img className="galimg" src="./images/s3.png" alt="gal1" />
            <img className="galimg" src="./images/s6.png" alt="gal1" />
            <img className="galimg" src="./images/s5.png" alt="gal1" />
          </div>
        ) : null}

        {galState === "1" ? (
          <div className="gal_flex">
            <img className="galimg" src="./images/s6.png" alt="gal1" />
            <img className="galimg" src="./images/s5.png" alt="gal1" />
            <img className="galimg" src="./images/s3.png" alt="gal1" />
            <img className="galimg" src="./images/s1.png" alt="gal1" />
            <img className="galimg" src="./images/s2.png" alt="gal1" />
            <img className="galimg" src="./images/s4.png" alt="gal1" />
          </div>
        ) : null}
      </section>
      <Footer />
    </div>
  );
}
