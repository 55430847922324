import React, { useEffect, useState, useRef } from "react";
import { NotificationManager } from "react-notifications";
import { front, http } from "../utils/constant";

import axios from "axios";

export const EventContext = React.createContext();

export const EventProvider = ({ children }) => {
  const signin = async (cred) => {
    const res = await axios.post(`${http}/api/v1/user/login`, cred);
    return res;
  };

  const signup = async (cred) => {
    const res = await axios.post(`${http}/api/v1/user/signup`, cred);
    return res;
  };

  const logout = () => {
    localStorage.removeItem("md-token");
    window.location.href = "/login";
  };

  const getProfile = async () => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.get(`${http}/api/v1/user`);
    return res;
  };

  const createProperty = async (cred) => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/property`, cred);
    return res;
  };

  const createPlan = async (cred) => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/plan`, cred);
    return res;
  };

  const createInvoice = async (cred) => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.post(`${http}/api/v1/payment`, cred);
    return res;
  };

  const updateUser = async (cred) => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.patch(`${http}/api/v1/user`, cred);
    return res;
  };

  const changePassword = async (cred) => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.patch(`${http}/api/v1/user/reset-password`, cred);
    return res;
  };

  const getTeam = async () => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.get(`${http}/api/v1/referral`);
    return res;
  };

  const getProperty = async () => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.get(`${http}/api/v1/property`);
    return res;
  };

  const getPlan = async () => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.get(`${http}/api/v1/plan`);
    return res;
  };

  const getInvoice = async () => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.get(`${http}/api/v1/payment/admin`);
    return res;
  };

  const getInvoice2 = async () => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.get(`${http}/api/v1/payment`);
    return res;
  };

  const getPlan2 = async () => {
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.get(`${http}/api/v1/plan/user`);
    return res;
  };

  const updatePayment = async (cred, id) => {
    console.log(id);
    const token = JSON.parse(localStorage.getItem("md-token"));
    axios.defaults.headers.common["Authorization"] = "JWT " + token;
    const res = await axios.patch(`${http}/api/v1/payment/${id}`, cred);
    return res;
  };

  return (
    <EventContext.Provider
      value={{
        signin,
        signup,
        logout,
        getProfile,
        createProperty,
        updateUser,
        changePassword,
        getTeam,
        getProperty,
        createPlan,
        getPlan,
        createInvoice,
        getInvoice,
        updatePayment,
        getInvoice2,
        getPlan2,
      }}
    >
      {children}
    </EventContext.Provider>
  );
};
