import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Terms() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />

      <section className="policy_section1">
        <div className="policy_text21">Terms & Conditions</div>
        <div className="policy_text11">
          The following General Terms and Conditions apply to rental contracts
          for hotel accommodation as well as to further services and deliveries
          provided by Bakerverse Suites Limited. We kindly ask that you take a
          moment to read them prior to making a Booking.
        </div>
      </section>

      <section className="policy_text2">
        <div>
          <div className="policy_head">IMPORTANT DEFINITIONS</div>
          <ul>
            <li className="list">
              “Company” or “We” or “Us” means Bakerverse Suites Limited (with
              Registration Number RC0000000) whose registered office is at 3
              Erimionren Close, off Osamagioghomwenwi Street, Orai Town, Oluku
              Isiohor, Benin City, Nigeria, or its applicable group company.
            </li>
            <li className="list">
              Client” or “Guest” or “You” refers to the customer making
              reservations in our hotel
            </li>
            <li className="list">
              “Booking” means the booking for accommodation, functions and/or
              any other services or items made with us.
            </li>
            <li className="list">
              “Contract” Shall mean this rental booking for accommodation,
              together with all the terms and conditions set out in it as agreed
              upon and stated to apply to the Booking.
            </li>
            <li className="list">
              “Hotel” may be defined as a commercial establishment that provides
              paid lodging to guests and sometimes permanent resident which may
              also consist of the conference room, bar and restaurant.
            </li>
            <li className="list">
              “Hotel Manager or Hotelier” means the person in charge of the
              hotel operations as specified on the booking confirmation.
            </li>
            <li className="list">“Terms” means these terms and conditions.</li>
            <li className="list">
              “Website” means{" "}
              <a href="https://www.bakerversuites.com">
                https://www.bakerversuites.com
              </a>{" "}
              or any other website owned or operated by us relating to the hotel
              from time to time. 
            </li>
            <li className="list">“VAT” means value added tax.</li>
          </ul>
          <div className="policy_head">RULES</div>
          <ul>
            <li className="list">
              Do not use the guest rooms for purposes other than intended
              without written authorization of the Hotel manager or Hotelier.
            </li>
            <li className="list">
              Do not light fire in the passage or guest rooms for heating or
              cooking. 
            </li>
            <li className="list">
              To prevent fire accident, refrain from smoking on bed, in
              non-smoking rooms, and in any other places prone to fire outbreak.
            </li>
            <li className="list">
              The equipment and articles in guest rooms are strictly meant for
              the guests staying in the Hotel. Hence, inside the guest rooms,
              use of such equipment and articles by outsiders is prohibited.
            </li>
            <li className="list">
              Be careful not to move the articles in the Hotel or guest rooms
              from their fixed places without written authorization of the Hotel
              manager or Hotelier.
            </li>
            <li className="list">
              Do not change the position of the fittings and fixtures in the
              Hotel or guest rooms without permission.
            </li>
            <li className="list">
              Do not bring the following inside the hotel premises:
              <ul>
                <li>Animals, birds, etc. </li>
                <li>
                  Things giving off an offensive odour to a reasonable man 
                </li>
                <li>
                  Lodging baggage exceeding the normal amount that can be
                  carried into a hotel 
                </li>
                <li>
                  Guns, swords, and other harmful and violence related weapon
                  and devices, etc. 
                </li>
                <li>
                  Explosives, or substances containing volatile oils that may
                  ignite or catch fire 
                </li>
                <li>
                  Any other harmful object that may pose a threat to the safety
                  of other guests staying at the Hotel{" "}
                </li>
              </ul>
            </li>
            <li className="list">
              Do not scream, sing loudly, or create loud noises by any other
              actions inside the Hotel, guest rooms or including the Hotel
              premises as it may disturb or annoy other guests staying in the
              Hotel and neighborhood. 
            </li>
            <li className="list">
              Refrain from engaging in gambling or acts that violate public
              order and morals inside the Hotel or guest rooms.
            </li>
            <li className="list">
              Do not distribute advertisement goods or sell articles to the
              other guests or collect donation or signatures from them inside
              the Hotel premises, without proper permission. 
            </li>
            <li className="list">
              Note that we may refuse stay to patients suffering from an illness
              or with contagious diseases and/or ailment that may cause
              discomfort of any kind to the other guests inside the Hotel. 
            </li>
            <li className="list">
              Do not leave your personal belongings in the passages or the
              lobby. 
            </li>
            <li className="list">
              Any acts of photography or otherwise that may occasion any form of
              inconveniences or nuisance to the wellbeing guests at the Hotel or
              to the Hotel in general are prohibited.
            </li>
            <li className="list">
              All personal meetings should be held in the 1st floor lobby
              only.  
            </li>
            <li className="list">
              In principle, the guest rooms accommodating the same guests
              continuously for two or more nights shall not be cleaned during
              their period of stay. Such rooms, however, shall be cleaned once
              in six days to maintain cleanliness. Further, if cleaning of one
              or more of the guest rooms is deemed necessary by the hotel
              authorities, the guests occupying the room(s) shall not have a
              right to deny such room cleaning.  
            </li>
          </ul>
          <div className="policy_head">SECTION 1: BOOKINGS </div>
          <div>
            All Bookings at the Hotel are subject to these terms. <br />
            <br />
            At the time of booking or at check-in, we may obtain the Client’s
            bank account or credit/debit card details and you shall authorize
            the use of the said credit/debit card to recover the monies due to
            us for the services rendered by our hotel. We shall also have the
            right to request full payment in advance or a deposit at the time of
            booking in certain circumstances or if the Booking includes the
            supply of certain items or services. No Booking shall be treated as
            confirmed until the details and/or payment/deposit described in this
            paragraph have been provided.
            <br />
            <br />
            The following particulars will be required when the Guest is making
            a reservation: <br />
            <br /> 1. Name, address, age, gender, nationality, and occupation of
            the Guest <br />
            2. Date of accommodation and estimated time of arrival <br />
            3. Other particulars deemed necessary by the Hotel <br />
            <br />
            If Guests request to extend their stay, during their stay at the
            Hotel, beyond the date in subparagraph (2) of the preceding
            Paragraph, it shall be regarded as an application for a new
            Accommodation Contract at the time such request is made. <br />
            <br />
          </div>
          <div className="policy_head">
            SECTION 2: SUBLETTING AND RE-LETTING{" "}
          </div>
          <div>
            Subletting and re-letting of the rooms as well as the use of the
            rooms or any other purpose than accommodation requires the consent
            of the manager or hotelier in writing. Contrary conditions of the
            client shall only apply prior to written agreement.
            <br />
            <br />
            Guests will not let others stay at the Hotel, assign or resale
            Accommodation Agreements, or let others use Hotel’s facilities for
            Guests only without the Hotel’s prior written consent <br />
            <br />
          </div>
          <div className="policy_head">
            SECTION 3: CONCLUSION OF THE CONTRACT, CONTRACTING PARTIES, CONTRACT
            LIABILITIES
          </div>
          <div>
            a) This contract becomes effective upon the confirmation and
            acceptance of the fees payable and the receipt of the requisite
            documents furnished by the guest to the Hotel Manager or Hotelier.
            The hotel is free to confirm the booking of rooms in written form.{" "}
            <br /> <br />
            b) Deposits paid by the guest shall firstly be used to offset for
            the Total Accommodation Charge to be paid by the Guest, secondly for
            cancellation charges as stipulated in “Section 6” and thirdly for
            reparations under Section 11 as applicable. The balance, if any,
            shall be refunded at the time of payment for accommodation as stated
            in Section 7. <br /> <br />
            c) Contracting parties are the Hotel and the Client. If a
            third-party order on account of the Client, the third party is
            jointly and severally liable with the Client for all contract
            obligations provided the hotel has an appropriate statement from the
            third party. <br /> <br />
            d) Claims for damage become statute-barred in six years in
            compliance with the Statues of limitation. Reduction of limitation
            shall not apply to claims based on deliberate or gross breach of
            duty at the hotel. <br /> <br />
            e) If the Guest fails to pay the deposit by the date as stipulated
            in “Sub-section b of Section 3”, the Accommodation Contract shall be
            treated as invalid. However, the same shall apply only in the case
            where the Guest is thus informed by the Hotel manager or Hotelier
            when specifying the period of Payment of the deposit. <br /> <br />
          </div>
          <div className="policy_head">
            SECTION 4: REFUSAL OF ACCOMMODATION CONTRACTS <br /> <br />
          </div>
          <div>
            The Hotel may refuse to conclude an Accommodation Contract under any
            of the following circumstances if: <br />
            <br />
            1. The application for accommodation does not conform with the
            provisions of these Terms and Conditions. <br />
            2. All the guest rooms in the Hotel are booked. <br />
            3. The person seeking Hotel accommodation is likely to violate laws
            of the Federal Republic of Nigeria and ordinances or act against the
            public order or good morals regarding his/her accommodation. <br />{" "}
            4. The person seeking Hotel accommodation belongs to or is related
            to an organized crime group or is a recognized criminal or related
            party of an organized crime group.
            <br />
            5. The person seeking Hotel accommodation is a member of a
            corporation or other group managed or administered by a gang or gang
            members. <br />
            6. The person seeking Hotel accommodation is a member of a
            corporation, whose employees include one or more gang members <br />
            7. The person seeking Hotel accommodation performs any act that
            causes significant disturbance to other guests <br />
            8. The person seeking Hotel accommodation engages in coercive acts
            such as violence with the Hotel staff, threatening or blackmailing
            the Hotel staff, or makes an unreasonable demand, or is known to
            have a past record of similar act(s). <br />
            9. The person seeking Hotel accommodation can be clearly identified
            as carrying an infectious disease. <br />
            10. The Hotel is unable to provide accommodation due to natural
            calamities, malfunction of facilities and/or other unavoidable
            causes.
            <br />
            11. The person seeking Hotel accommodation is intoxicated and is
            likely to cause annoyance to other guests <br />
            12. The person seeking Hotel accommodation applies for a room with a
            hidden intent of raising profit for himself or a third party by
            engaging in acts such as selling items of any form anywhere inside
            the hotel premises or in the Hotel guest room booked by him. <br />
            13. The person seeking Hotel accommodation fails to abide by the
            provisions made under these Terms & Conditions or the provisions
            regarding payment and/or room cancellation clarified at the time of
            booking. <br />
            <br />
          </div>
          <div className="policy_head">
            SECTION 5: CANCELLATION OF THE CONTRACT BY THE CLIENT <br /> <br />
          </div>
          <div>
            a) The client has the right to cancel the contract only prior to the
            written consent of the hotel. In case this written consent is not
            obtained, the contracted price is to be paid even if the client does
            not use all contracted services.
            <br /> <br />
            b) Where the Guest has canceled the Accommodation Contract in whole
            or in part due to causes for which the Guest is liable (except when
            the Hotel has requested the payment of the Accommodation Deposit in
            a specified period as prescribed in “Sub-section d of Section 3” and
            the Guest has canceled the contract before the payment), the Guest
            shall pay cancellation charges as listed in the Attached Table 2.
            <br /> <br />
            c) Where a termination date for the contractual agreement between
            the Hotel and the Client was agreed upon in writing, the contractual
            agreement shall be deemed terminated. Where the Client desires to
            terminate the said contractual agreement before the expiration date,
            the client may do so in writing subject to the consent of the Hotel
            manager or Hotelier.
            <br /> <br />
            d) If the client does not use all contracted rooms and the hotel is
            able to sell the rooms otherwise, the hotel must credit the room
            rent and as well as other saved expenditures. <br /> <br />
            e) The hotel has the right to charge a lump sum for any damage
            caused directly or indirectly and reimbursed by the client. Then the
            client is obliged to pay 90% for the room nights with or without
            breakfast, 70% for half-board and 60% for full-board service of the
            contracted price. The client is free to prove that there is no
            damage or less than the amount required. <br /> <br />
          </div>
          <div className="policy_head">
            SECTION 6: CANCELLATION OF THE CONTRACT BY THE HOTEL <br /> <br />
          </div>
          <div>
            a) Provided the Client’s right to cancel the contract within a fixed
            time was contracted in writing, the hotel on its part has the right
            to cancel the contract if there are requests from other clients for
            the contracted rooms and the Client renounces his right to withdraw
            from the contract upon enquiry. Place of fulfillment and
            jurisdiction shall be the domicile of the hotel. <br /> <br />
            b) If the agreed deposit of the contracted sum is not made even
            after an appropriately extended deadline with a notice of rejection
            set by the hotel, the hotel has the right to withdraw from the
            contract. <br /> <br />
            c) In addition, the hotel has the right to cancel contract
            extraordinarily due to an objective justified reason, for example if
            force majeure or other circumstances beyond the control of the hotel
            make fulfillment of contract impossible <br />
            - rooms are booked on misleading or false statements regarding the
            person or the purpose. <br />
            - the hotel has reasonable grounds to assume that use of the hotel
            services may endanger the hotel operation, the security, or the
            image of the hotel in public without being attributed to the
            territory and/or organization of the hotel.
            <br />- there is a violation of the above-mentioned rules and
            regulations. <br />
            <br />
            d) Without delay the hotel must inform the client about the
            execution of the right of withdrawal. <br /> <br />
            e) Provided a rightful withdrawal from the contract, the client is
            not entitled to claim damages. <br /> <br />
          </div>
          <div className="policy_head">
            SECTION 7: SERVICE, PRICE, PAYMENT, INVOICING <br /> <br />
          </div>
          <div>
            a) The hotel is obliged to have the rooms ready for the client and
            to provide the service as contracted. <br /> <br />
            b) In order to use the room and the additional services provided by
            the hotel, the client is obliged to pay the valid price as listed in
            the Attached Table 1. This also applies to services and expenses of
            the hotel towards third arranged by the client. <br /> <br />
            c) The prices contracted include the legal value added tax. Provided
            the period between conclusion and performance of the contract
            exceeds four months and the calculated price for hotel services
            increases, the hotel has the right to increase the contracted price
            by 5% at the most. <br /> <br />
            d) The hotel reserves the right to adjust prices in case the client
            requires later amendments regarding the contracted number of rooms,
            hotel services or length of stay and the hotel agrees to it. <br />{" "}
            <br />
            e) Accommodation charges shall be paid even if the Guest voluntarily
            does not utilize the accommodation facilities provided to him/her by
            the Hotel. <br /> <br />
            f) Invoices of the hotel without settlement date are payable with no
            discount within ten (10) days of the invoice date if not contracted
            differently. In case of delay of payment, the hotel has the right to
            charge penal interest. The hotel reserves the right to prove greater
            damage. <br /> <br />
            g) The hotel reserves the right to charge an appropriate advance
            payment or bail upon conclusion of the contract or later with
            regards to legal terms of all-inclusive travelling. The amount of
            the advance payment and payment date must be contracted in writing.{" "}
            <br /> <br />
            h) The client is only entitled to offset or reduce a hotel claim
            subject to an indisputable or legally valid claim. <br /> <br />
          </div>
          <div className="policy_head">
            SECTION 8: OCCUPANCY HOURS OF GUEST ROOMS
          </div>
          <ul>
            <li className="list">
              The Guests staying at the Hotel shall be entitled to use the guest
              rooms from 3:00PM on the day of arrival to 12:00PM noon the
              following day. However, if the Guest is accommodated continuously
              for some period, the Guest may occupy the guest room all day,
              except for the days of arrival and departure.
            </li>
            <li className="list">
              Notwithstanding the provisions prescribed in the preceding
              Paragraph, the Hotel may permit the Guest to occupy the guest room
              beyond the time prescribed in the same Paragraph. However, in this
              case, extra charges shall be applicable.
            </li>
          </ul>
          <div className="policy_head">
            When Unable to Provide Contracted Rooms
          </div>
          <ul>
            <li className="list">
              When Unable to Provide Contracted Rooms to the Guest, the Hotel
              shall arrange accommodation of the same standard as soon as
              possible elsewhere, with the consent of the Guest.
            </li>
            <li className="list">
              Subject to the above paragraph, where all efforts to provide an
              alternative arrangement for the Guest by the hotel has proven
              abortive, the Hotel shall pay a compensation fee equivalent to the
              cancellation charges, and the compensation fee shall be applied to
              reparations. However, if the Hotel is not able to provide
              alternative accommodation due to causes for which the Hotel is not
              liable, the compensation fee shall not be paid to the Guest.
            </li>
          </ul>
          <div className="policy_head">Handling of Deposited Articles</div>
          <ul>
            <li className="list">
              If an article, valuable or money deposited by the Guest at the
              front desk of the hotel are subsequently discovered to be lost or
              damaged by an act of a staff of the Hotel, the Hotel shall
              compensate the Guest to the extent of the damage or loss except
              where the damage or loss was due to force majeure, the Hotel shall
              compensate for the damage, except in the cases when this has
              occurred due to causes of force majeure. However, for cash and
              valuables, where the Hotel has requested the Guest for an
              appraisal of the value and the Guest has fails to do so, the Hotel
              shall compensate the Guest up to a maximum of N100,000.
            </li>
            <li className="list">
              The Hotel shall compensate the Guest for the loss and damages to
              the guest's properties not deposited at the front desk and proven
              to be caused by an act or negligence of a staff of the Hotel.
              However, where articles of which the nature and value have not
              been reported in advance by the Guest, the Hotel shall compensate
              the Guest up to a tune of the sum of N100,000.
            </li>
            <li className="list">
              Artwork and/or antiques shall not be accepted as deposits at the
              Hotel.
            </li>
          </ul>
          <div className="policy_head">
            Custody of Baggage and/or Belongings of Guest
          </div>
          <ul>
            <li className="list">
              When the baggage of the Guest is brought into the Hotel before
              his/her arrival, the Hotel shall be liable to store it only in the
              case when the Guest's request to keep his baggage has been
              accepted by the Hotel in advance. The baggage shall be handed over
              to the Guest at the front desk at the time of check-in.
            </li>
            <li className="list">
              When the baggage or belongings of the Guest are found after
              check-out and ownership of the article is confirmed, the Hotel
              shall inform the owner of the article left and ask for further
              instructions. When no such instructions are given to the Hotel by
              the owner or when ownership is not confirmed, the valuables or
              articles containing personal information shall be handed over to
              the nearest police station within 7 days of finding them. Any
              other articles, if not claimed within 3 months after they are
              found, shall be disposed of appropriately. However, any food or
              beverages that may affect the cleanliness of the Hotel, and other
              things such as cigarettes and magazines shall be disposed of on
              the same day on which they are found.
            </li>
            <li className="list">
              The liability of the Hotel to keep the hand baggage and/or
              belongings of the Guest in the cases described above shall be as
              per the provisions of Paragraph 1 and 2 respectively of the
              preceding Article.
            </li>
          </ul>
          <div className="policy_head">
            SECTION 9: LIABILITY REGARDING PARKING
          </div>
          <div>
            The Hotel shall not be liable for the custody of the vehicle of the
            Guest when the Guest utilizes the parking lot of the Hotel, as the
            Hotel only provides the space for parking and cannot be held
            responsible for management of the vehicle, whether the key to the
            vehicle has been deposited to the Hotel or not.
          </div>
          <div className="policy_head">SECTION 10: LIABILITY OF THE GUEST</div>
          <div>
            The Guest shall compensate the Hotel for any damage caused due to
            intent or negligence on the part of the Guest
          </div>
          <div className="policy_head">
            SECTION 11: JURISDICTION AND APPLICABLE LAWS{" "}
          </div>
          <div>
            Any dispute or claim arising out of or in connection with the Hotel
            and the Guest and/or regarding the Rental contract shall be governed
            by and construed in accordance with the law of the Federal Republic
            of Nigeria by the Courts of Nigeria Attached Table 1 Breakdown of
            the Accommodation charges and other charges (Ref. Section 1,
            Sub-section b of Section 3, and Sub-section b of Section 7)
          </div>
          <br /> <br />
          <table>
            <tr>
              <th colspan="3">Breakdown</th>
            </tr>

            <tr>
              <td rowSpan="3">Total Amount to be paid by the guest</td>
              <td>Accommodation charges </td>
              <td>Basic Accommodation Charge (Room Charge) </td>
            </tr>
            <tr>
              <td>Extra Charge </td>
              <td>Other Usage Fee </td>
            </tr>
            <tr>
              <td>Tax</td>
              <td>
                Consumption Tax <br />
                Accommodation Tax <br />* Accommodation tax is subject to change
                depending on the tax regulations of the administrative division
              </td>
            </tr>
          </table>
          <br />
          <div>
            These charges are subject to change in accordance with revisions to
            the Tax Laws concerned. <br /> <br />
          </div>
          <div>
            Attached Table 2　Cancellation Charge (Ref. Section 5, Sub-section
            b) <br /> <br />
          </div>
          <div>
            The Guest shall compensate the Hotel for any damage caused due to
            intent or negligence on the part of the Guest{" "}
          </div>
          <br />
          <table>
            <tr>
              <td>Day of notification of cancellation of contract </td>
              <td>1 day prior </td>
              <td>Same day </td>
              <td>No-show </td>
            </tr>
            <tr>
              <td>Proportion of cancellation charges</td>
              <td>20% </td>
              <td>80% </td>
              <td>100% </td>
            </tr>
          </table>
          <br />
          <div className="policy_head">Note:</div>
          <ul>
            <li className="list">
              {" "}
              Percentage denotes the cancellation charges to be cut from the
              paid Accommodation Charges.{" "}
            </li>
            <li className="list">
              When the contract duration is reduced, cancellation charges for
              one day (first day) shall be paid by the Guest regardless of how
              many days are reduced from the contract duration.{" "}
            </li>
            <li className="list">
              For group (of 15 or more Guests) bookings, a separate cancellation
              policy stipulated by the Hotel may be applicable. This separate
              cancellation policy shall take precedence over these Terms and
              Conditions, and the Guests applying for Accommodation Contract and
              the existing Guests shall be charged an appropriate amount of
              cancellation charges.{" "}
            </li>
          </ul>
        </div>
      </section>
      <Footer />
    </div>
  );
}
