import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Rooms() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <section className="rooms_section1">Rooms & Cloud 9</section>
      <section className="rooms_section2">
        <div className="rooms_text1">All Rooms</div>
        <div className="rooms_flex">
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms2.png" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 15,000/ Night</div>
            <div className="rooms_text3">Wonderland (Room 101)</div>
            <div className="rooms_text4">
              <span>1 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms3.png" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 25,000/ Night</div>
            <div className="rooms_text3">Metarealms (Room 102)</div>
            <div className="rooms_text4">
              <span>2 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms4.png" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 15,000/ Night</div>
            <div className="rooms_text3">Nifty Dune (Room 103)</div>
            <div className="rooms_text4">
              <span>1 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms5.png" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 15,000/ Night</div>
            <div className="rooms_text3">Planet Atlantis (Room 201)</div>
            <div className="rooms_text4">
              <span>1 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms6.png" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 15,000/ Night</div>
            <div className="rooms_text3">Decentraland (Room 202)</div>
            <div className="rooms_text4">
              <span>1 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms7.png" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 25,000/ Night</div>
            <div className="rooms_text3">Dripverse (Room 203)</div>
            <div className="rooms_text4">
              <span>2 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms8.png" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 15,000/ Night</div>
            <div className="rooms_text3">Metalodge (Room 204)</div>
            <div className="rooms_text4">
              <span>1 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms9.png" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 20,000/ Night</div>
            <div className="rooms_text3">Etherverse (Room 205)</div>
            <div className="rooms_text4">
              <span>2 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms10.png" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 15,000/ Night</div>
            <div className="rooms_text3">Terraville (Room 206)</div>
            <div className="rooms_text4">
              <span>1 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
          <div className="rooms_box">
            <img className="roomsimg" src="../images/rooms11.jpg" alt="room2" />
            <hr className="rooms_hr" />
            <div className="rooms_text2">₦ 30,000/ Night</div>
            <div className="rooms_text3">Drippy Ville (Room 207)</div>
            <div className="rooms_text4">
              <span>1 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
