import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Room() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <section className="room_section1"></section>
      <section className="room_section2">
        <div className="room_text1">Metarealms (Room 102)</div>
        <div className="room_flex1">
          <div className="">
            <div className="room_text2">₦ 70,000/ Night</div>
            <div className="room_text3">
              <span>1 King Size Bed</span> | <span>1 Big Bathroom </span> |
              <span> 2 Guests </span>
            </div>
            <div className="room_text4">About This Room</div>
            <div className="room_text5">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Caucus
              tellus, nam dui sapien molestie vulputate rhoncus fermentum. Sit
              cursus ultrices et pellentesque iaculis pellentesque viverra
              facilisis. Neque viverra donec sed sed quisque. Viverra metus nisl
              pulvinar aliquet blandit pulvinar{" "}
            </div>

            <div className="room_box1">
              <div className="room_text6">Room Amenities</div>

              <div>
                <div className="room_text_flex">
                  <img src="../images/check.svg" alt="check" />
                  <span className="room_text7">Air Condition</span>
                </div>
                <div className="room_text_flex">
                  <img src="../images/check.svg" alt="check" />
                  <span className="room_text7">Heater</span>
                </div>
                <div className="room_text_flex">
                  <img src="../images/check.svg" alt="check" />
                  <span className="room_text7">Bathrobe & Slippers</span>
                </div>
                <div className="room_text_flex">
                  <img src="../images/check.svg" alt="check" />
                  <span className="room_text7">Shower-Bathub combination</span>
                </div>
                <div className="room_text_flex">
                  <img src="../images/check.svg" alt="check" />
                  <span className="room_text7">Work Desk</span>
                </div>
                <div className="room_text_flex">
                  <img src="../images/check.svg" alt="check" />
                  <span className="room_text7">Balcony</span>
                </div>

                <div className="room_text_flex">
                  <img src="../images/check.svg" alt="check" />
                  <span className="room_text7">24/7 internet connection</span>
                </div>

                <div className="room_text_flex">
                  <img src="../images/check.svg" alt="check" />
                  <span className="room_text7">Breakfast Included</span>
                </div>

                <div className="room_text_flex">
                  <img src="../images/check.svg" alt="check" />
                  <span className="room_text7">Toiletries</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img className="roomimg" src="../images/room1.png" alt="room" />
            <img className="roomimg" src="../images/room2.png" alt="room" />
            <img className="roomimg" src="../images/room2.png" alt="room" />
          </div>
        </div>
      </section>
      <section className="room_section3">
        <div className="room_text8">Similar Rooms</div>
        <div className="room_list">
          <div className="home_box">
            <img className="home_img1" src="./images/room4.png" alt="img2" />
            <div className="home_text3">
              {" "}
              <hr className="text_hr" />
              <div>Wonderland (Room 101)</div>
            </div>
          </div>
          <div className="home_box">
            <img className="home_img1" src="./images/room5.png" alt="img3" />
            <div className="home_text3">
              <hr className="text_hr" />
              <div>Nifty Dune (Room 103)</div>
            </div>
          </div>
          <div className="home_box">
            <img className="home_img1" src="./images/room6.png" alt="img4" />
            <div className="home_text3">
              <hr className="text_hr" />
              <div>Terraville (Room 206)</div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
