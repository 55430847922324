import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function NotFound() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />

      <img className="notimg" src="./images/404.svg" alt="404" />

      <div className="nottext">
        Sorry, something seems broken and our team are working on it or the page
        you’re looking for doesn’t exist
      </div>

      <Link to="/">
        <button className="not_but">Back home</button>
      </Link>

      <Footer />
    </div>
  );
}
