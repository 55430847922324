import React, { useState, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Gallery() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [galState, setState] = useState("all");

  const changeGal = (state) => {
    setState(state);
  };
  return (
    <div>
      <Header />
      <section>
        <div className="gal_text1">Quick view of Bakerverse suites</div>
        <img src="./images/gal.png" alt="gallery" className="gal" />
      </section>

      <section>
        <div className="gal_bar">
          <div
            className={galState === "all" ? "active" : "none"}
            style={{ cursor: "pointer" }}
            onClick={() => changeGal("all")}
          >
            All
          </div>
          <div
            className={galState === "rooms" ? "active" : "none"}
            style={{ cursor: "pointer" }}
            onClick={() => changeGal("rooms")}
          >
            Rooms
          </div>
          <div
            className={galState === "bar" ? "active" : "none"}
            style={{ cursor: "pointer" }}
            onClick={() => changeGal("bar")}
          >
            PoolBar
          </div>
          <div
            className={galState === "dinning" ? "active" : "none"}
            style={{ cursor: "pointer" }}
            onClick={() => changeGal("dinning")}
          >
            Restaurant
          </div>
          <div
            className={galState === "amenities" ? "active" : "none"}
            style={{ cursor: "pointer" }}
            onClick={() => changeGal("amenities")}
          >
            Amenities
          </div>
          <div
            className={galState === "virtual" ? "active" : "none"}
            style={{ cursor: "pointer" }}
            onClick={() => changeGal("virtual")}
          >
            Virtual Tour
          </div>
        </div>

        {galState === "all" ? (
          <div className="gal_flex">
            <img className="galimg" src="./images/gal1.png" alt="gal1" />
            <img className="galimg" src="./images/gal2.png" alt="gal1" />
            <img className="galimg" src="./images/gal3.png" alt="gal1" />
            <img className="galimg" src="./images/gal4.png" alt="gal1" />
            <img className="galimg" src="./images/gal5.png" alt="gal1" />
            <img className="galimg" src="./images/gal6.png" alt="gal1" />
            <img className="galimg" src="./images/pool1.png" alt="gal1" />
            <img className="galimg" src="./images/pool2.png" alt="gal1" />
            <img className="galimg" src="./images/pool3.png" alt="gal1" />
            <img className="galimg" src="./images/pool4.png" alt="gal1" />
            <img className="galimg" src="./images/pool5.png" alt="gal1" />
            <img className="galimg" src="./images/r1.png" alt="gal1" />
            <img className="galimg" src="./images/r2.png" alt="gal1" />
            <img className="galimg" src="./images/r3.png" alt="gal1" />
            <img className="galimg" src="./images/r4.png" alt="gal1" />
            <img className="galimg" src="./images/r5.png" alt="gal1" />
            <img className="galimg" src="./images/r6.png" alt="gal1" />
            <img className="galimg" src="./images/r7.png" alt="gal1" />
            <img className="galimg" src="./images/r8.png" alt="gal1" />
            <img className="galimg" src="./images/r9.png" alt="gal1" />
            <img className="galimg" src="./images/a1.png" alt="gal1" />
            <img className="galimg" src="./images/a2.png" alt="gal1" />
            <img className="galimg" src="./images/a3.png" alt="gal1" />
          </div>
        ) : null}

        {galState === "rooms" ? (
          <div className="gal_flex">
            <img className="galimg" src="./images/gal1.png" alt="gal1" />
            <img className="galimg" src="./images/gal2.png" alt="gal1" />
            <img className="galimg" src="./images/gal3.png" alt="gal1" />
            <img className="galimg" src="./images/gal4.png" alt="gal1" />
            <img className="galimg" src="./images/gal5.png" alt="gal1" />
            <img className="galimg" src="./images/gal6.png" alt="gal1" />
          </div>
        ) : null}

        {galState === "bar" ? (
          <div className="gal_flex">
            <img className="galimg" src="./images/pool1.png" alt="gal1" />
            <img className="galimg" src="./images/pool2.png" alt="gal1" />
            <img className="galimg" src="./images/pool3.png" alt="gal1" />
            <img className="galimg" src="./images/pool4.png" alt="gal1" />
            <img className="galimg" src="./images/pool5.png" alt="gal1" />
          </div>
        ) : null}

        {galState === "dinning" ? (
          <div className="gal_flex">
            <img className="galimg" src="./images/r1.png" alt="gal1" />
            <img className="galimg" src="./images/r2.png" alt="gal1" />
            <img className="galimg" src="./images/r3.png" alt="gal1" />
            <img className="galimg" src="./images/r4.png" alt="gal1" />
            <img className="galimg" src="./images/r5.png" alt="gal1" />
            <img className="galimg" src="./images/r6.png" alt="gal1" />
            <img className="galimg" src="./images/r7.png" alt="gal1" />
            <img className="galimg" src="./images/r8.png" alt="gal1" />
            <img className="galimg" src="./images/r9.png" alt="gal1" />
          </div>
        ) : null}

        {galState === "amenities" ? (
          <div className="gal_flex">
            <img className="galimg" src="./images/a1.png" alt="gal1" />
            <img className="galimg" src="./images/a2.png" alt="gal1" />
            <img className="galimg" src="./images/a3.png" alt="gal1" />
          </div>
        ) : null}

        {galState === "virtual" ? (
          <div className="gal_flex" style={{ marginBottom: "100px" }}>
            <iframe
              title="virtual"
              width="100%"
              height="640"
              frameborder="0"
              allow="xr-spatial-tracking; gyroscope; accelerometer"
              allowfullscreen
              scrolling="no"
              src="https://kuula.co/share/collection/790P6?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
            ></iframe>
          </div>
        ) : null}
      </section>
      <Footer />
    </div>
  );
}
