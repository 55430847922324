import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Privacy() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />

      <section className="policy_section1">
        <div className="policy_text1">Privacy Policy</div>
      </section>

      <section className="policy_text2">
        <div>
          The Privacy Policy forms part of the General Conditions that govern
          this <a href="https://bakerversesuites.com/">Website</a> together with
          the Policy of Cookies and the Legal Warning. BAKERVERSE SUITES LIMITED
          reserves the right to modify or adapt this Privacy Policy at any time.
          Therefore, we recommend that you review it each time you access our
          Website. In the event that a user has registered on the website and
          accesses their account or profile, when accessing it, you will be
          informed in the event that there have been substantial changes in
          relation to the processing of their personal data.
        </div>

        <div>
          <div className="policy_head">
            Who is responsible for the processing of your data?
          </div>
          <div>
            The data collected or voluntarily provided by any means on this
            Website, whether by browsing type, contact forms, via email or
            telephone, will be collected and processed by the File Manager,
            whose data are indicated below. <br /> <br />
          </div>

          <div>
            BAKERVERSE SUITES LIMITED <br />
            Address: 3 Erimionren Close, off Osamagioghomwenwi Street, Orai
            Town, Benin City <br />
            Tel: <br />
            Email: <br />
            If, for any reason, you wish to contact us in any matter relating to
            the processing of your personal data or privacy (with our Data
            Protection Officer), you can do so through any of the means
            indicated above.
          </div>
        </div>

        <div>
          <div className="policy_head">Non-Personal Identification Data</div>
          <div>
            For the simple fact of navigating the Website, BAKERVERSE SUITES
            LIMITED will collect information referring to:
            <br />
            <ul>
              <li>IP address</li>
              <li>Browser version</li>
              <li>Operating system</li>
              <li>User session or navigation through the Website</li>
              <li>Geolocation of the User</li>
            </ul>
            Such information is stored by Google Analytics, so we refer to the
            Privacy Policy of Google, as it collects and treats such
            information. Details can be found here -
            <a href="https://www.google.com/intl/en/policies/privacy">policy</a>
            .<br />
            <br /> In the same way, our Website utilizes Google Maps features,
            which may have access to your location, if you allow it, in order to
            provide a greater specificity on the distance and/or roads our
            headquarters. In this regard, we refer to the Privacy Policy used by
            Google Maps, in order to know the use and processing of such data{" "}
            <a href=" https://www.google.com/intl/en/policies/privacy">
              {" "}
              Privacy Policy
            </a>{" "}
            .<br />
            <br /> The information that we handle will not be related to a
            specific user and will be stored in our databases for the purpose of
            statistical analysis, improvements in the Website, understanding how
            the User interact with our products and/or services, and help us
            improve our business strategy. The data will not be communicated to
            third parties.
          </div>
        </div>

        <div>
          <div className="policy_head">
            User Registration on The Website or Submission of Forms
          </div>

          <div>
            In order to access certain products and/or services, such as
            reservations/contact requests, the user must fill in a form. In
            order to do this, a series of personal data is requested in the
            registration form. The data are necessary and obligatory to carry
            out such a registration. If you do not provide these fields, the
            registration will not be carried out. <br />
            <br />
            In this case, the navigation data will be associated with the user's
            registration data, identifying the same specific user who navigates
            uses our Website. In this way, it will be possible to personalize
            the offer of products and/or services that, in our opinion, best
            suits the user.
            <br />
            <br />
            The registration data of each user will be incorporated into the
            databases of BAKERVERSE SUITES LIMITED, together with the history of
            operations carried out by the same, and will be stored in the same
            until the account of the registered user is deleted. Once this
            account has been deleted, this information will be removed from our
            databases, keeping apart for 10 years those data relating to the
            transactions made, without accessing or altering them, in order to
            comply with the deadlines legally in force. The data that are not
            linked to the transactions carried out will be maintained unless the
            consent is withdrawn, in which case they will be deleted immediately
            (always taking into account the legal deadlines).
            <br />
            <br />
            The legal basis for the processing of your personal data is the
            execution of a contract between the parties. In relation to the
            sending of communications and promotions by electronic means and the
            response to requests for information, the legitimacy of the
            processing is the consent of the data subject.
            <br />
            <br />
            The purposes of the processing will be the following:
            <ul>
              <li>Manage your access to the Website</li>
              <li>
                Manage the purchase of products and/or services made available
                to you through the Website.
              </li>
              <li>
                Keep you informed of the processing and status of your requests,
                purchases and/or reservations.
              </li>
              <li>Respond to your request for information.</li>
              <li>
                Manage all utilities and/or services offered by the platform to
                the user
              </li>
            </ul>
            Thus, we inform you that you may receive communications via email
            and/or your phone, in order to inform you of possible incidents,
            errors, problems and/or status of your requests.
            <br />
            <br />
            In order to send commercial communications, the user's express
            consent will be requested at the time of registration. In this
            respect, the user may revoke the consent given by contacting
            BAKERVERSE SUITES LIMITED, using the means indicated above. In any
            case, in each commercial communication, you will be given the
            opportunity to unsubscribe from receiving them, either through a
            link and/or email address.
            <br />
            <br />
          </div>
        </div>

        <div>
          <div className="policy_head">Contacts Through Social Networks</div>
          When engaging, interacting or contacting us through social media,
          BAKERVERSE SUITES LIMITED will collect information referring to:{" "}
          <br />
          <br />
          <ul>
            <li>User Location</li>
            <li>User personal profile details</li>
            <li>Gender</li>
            <li>Browser version</li>
            <li>Operating system</li>
          </ul>
          Such information is stored by the social media network provider, so we
          refer to the Privacy Policy for each of these Providers, as it
          collects and treats such information. Details of your acceptance of a
          contractual relationship in the environment of the corresponding
          social network, and in accordance with its privacy policies can be
          found here:
          <br />
          <br />
          Facebook: <a href="https://www.facebook.com/policy.php">
            policy
          </a>{" "}
          <br />
          <br />
          Instagram:{" "}
          <a href="https://help.instagram.com/155833707900388">policy</a> <br />
          <br />
          We can only consult or cancel your data in a restricted way by having
          a specific profile. We will treat them as long as you let us follow
          you, being friends or giving them to "like", "follow" or similar
          buttons.
          <br />
          <br />
          Any rectification of your data or restriction of information or
          publications must be done through the configuration of your profile or
          user in the social network itself.
          <br />
          <br />
        </div>

        <div>
          <div className="policy_head">
            Video Surveillance in the Hotel Premises
          </div>
          BAKERVERSE SUITES LIMITED uses video surveillance (live streams) to
          monitor security-relevant areas inside and outside its buildings and
          premises. In a few cases, videos are also recorded.
          <br />
          <br />
          Several cameras have been installed as part of a “virtual patrol” of
          the building concerned in the framework of Bakerverse Suites Limited’s
          general security concept. This enables the outer facade of the
          building, as well as its immediate vicinity, to be assessed quickly at
          all times, without endangering the company’s own personnel. In doing
          so, public spaces are principally not subjected to general video
          surveillance. As soon as you enter an area covered by camera
          surveillance, you become the subject of this data processing.
          <br />
          <br />
          Using surveillance cameras at BAKERVERSE SUITES LIMITED contributes
          significantly towards ensuring the most comprehensive security
          possible for its buildings, personnel, and customers in a way that
          saves and protects the wellbeing of its staff and/or customers.
          <br />
          <br />
          Depending on the prevailing circumstances of the locations concerned,
          we use the video surveillance to pursue the following goals:
          <ul>
            <li>
              To secure the outer facade of the building against vandalism
            </li>
            <li>To secure our facilities</li>
            <li>To perform the (necessary) monitoring of hotel premises</li>
            <li>To detect illegal attempts to enter our premises </li>
          </ul>
          Video surveillance allows counter-measures to be taken immediately in
          order to remedy defects in the above-mentioned points. They not only
          serve to protect our buildings, but also the personal safety and
          security of our customers.
          <br />
          <br />
          Note that video data are only evaluated where required. For
          infringements of house rules, perpetrations of criminal offences, and
          where legal guidelines so require, the recordings may, or must, be
          handed over to the law enforcement authorities. <br />
          <br />
        </div>

        <div>
          <div className="policy_head">Job Seekers</div>
          BAKERVERSE SUITES LIMITED will collect information and personal data
          when you apply for a position to work with us. This information may
          include, but not limited to, information you submit to us when you
          apply for a position to work with us. We collect and use these data
          only for organization of selection processes for hiring employees,
          appointing you for job interviews and evaluating your candidacy.
          <br />
          <br />
          If you have given us your consent, we will be able to transfer it to
          collaborating or related entities, with the sole aim of helping you
          find a job.
          <br />
          <br />
          What is the legitimacy for the treatment of your data?
          <br />
          <br />
          The legal basis is your unequivocal consent, when you give us your CV
          and receive and sign information regarding the treatments that we are
          going to carry out.
          <br />
          <br />
          How long will we keep the personal data?
          <br />
          <br />
          The CV will be stored for a period of one year, after which, if we
          have not contacted you, it will be deleted.
          <br />
          <br />
        </div>

        <div>
          <div className="policy_head">Third-party Data</div>
          We do not include or store personal data of third parties. As a
          general rule we only process the data provided by the owners. If you
          provide us with the data of third parties, you must first inform and
          request their consent from these people, or else you exempt us from
          any liability for failure to comply with this requirement.
        </div>

        <div>
          <div className="policy_head"> How We Protect Your Information</div>
          We adopt appropriate data collection, storage and processing practices
          and security measures to protect against unauthorized access,
          alteration, disclosure or destruction of your personal information,
          username, password, transaction information and data stored on our
          Website. We ensure that personal data collected from an individual. We
          ensure that personal data collected from an individual is kept secure
          and only accessible by authorized personnel. <br />
          <br />
          Should we need to transfer your data to another country, we would
          ensure that such country must have an adequate data protection law. We
          will seek your consent where we need to send your data to a country
          without an adequate data protection law.
        </div>

        <div>
          <div className="policy_head">
            How Long Will We Keep Your Personal Data?
          </div>
          The personal data will be maintained as long as you remain linked to
          us. <br /> <br />
          Once you disassociate yourself, the personal data processed for each
          purpose will be kept for the legally stipulated periods, including the
          period in which a judge or court may require them in accordance with
          the statute of limitations for legal actions.
          <br /> <br />
          The data processed will be kept as long as the legal periods referred
          to above do not expire, if there is a legal obligation to maintain, or
          if there is no such legal period, until the interested party requests
          its deletion or revokes the consent granted.
          <br /> <br />
          We will maintain all the information and communications relating to
          your purchase or the provision of our service, while the guarantees of
          the products or services last, to attend to possible claims.
          <br /> <br />
          What rights do you have?
          <br /> <br />
          At every given point in time, you have the rights:
          <ul>
            <li>To know if we are treating your data or not.</li>
            <li>To access your personal data</li>
            <li>
              To request the rectification of your data if they are inaccurate.
            </li>
            <li>
              To request the deletion of your data if they are no longer
              necessary for the purposes for which they were collected or if you
              withdraw your consent.
            </li>
            <li>
              To request the limitation of the treatment of their data, in some
              cases, in which case we will only keep them in accordance with
              current legislation.
            </li>
            <li>
              To file a complaint with the Nigerian Data Protection Agency, if
              you believe that we have not dealt with you correctly.
            </li>
            <li>
              To revoke consent for any treatment for which you have consented,
              at any time.
            </li>
          </ul>
          If you modify any data, please let us know so that we can keep them
          updated.
        </div>

        <div>
          <div className="policy_head">Changes to this Privacy Policy</div>
          BAKERVERSE SUITES LIMITED has the discretion to update this privacy
          policy at any time. When we do, we will revise the updated date at the
          bottom of this page. We encourage Users to frequently check this page
          for any changes to stay informed about how we are helping to protect
          the personal information we collect. You acknowledge and agree that it
          is your responsibility to review this privacy policy periodically and
          become aware of modifications.
        </div>

        <div>
          <div className="policy_head">Your Acceptance of these Terms</div>
          By using this Website, you signify your acceptance of this policy and
          terms of service. If you do not agree to this policy, please do not
          use our Website. Your continued use of the Website following the
          posting of changes to this policy will be deemed your acceptance of
          those changes.
        </div>
      </section>
      <Footer />
    </div>
  );
}
