import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Bar() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <section className="bar_section1">
        <div className="bar_text1">Restaurant & Bar</div>
        <img className="barimg" src="../images/bar1.png" alt="img" />
      </section>
      <img className="barimg2" src="../images/bar2.png" alt="img" />
      <section className="bar_section2">
        <div>
          <div className="bar_text2">Eat.Dine.Chill</div>
          <div className="bar_text3">
            Enjoy the very best of local dishes and cuisines in a comfortable
            and relaxed atmosphere. Our restaurant is open all day, greeting
            visitors with the aroma that's inviting. Carefully prepared with
            unique concepts and styles, there's no better place to be than under
            the bright and peaceful lounge/lobby where a fabulous dining
            experience awaits you.
          </div>
        </div>
        <img className="" src="../images/bar3.png" alt="img" />
      </section>
      <section className="bar_section3">
        <div className="bar_text4">Catch up with friends over a drink</div>
        <div className="bar_text5">
          With the perfect collection of whiskeys, popular larger brands, energy
          drinks and wines on display, and a variety of grilled chops and
          barbecue, you can enjoy the evenings in the ambience of our lush and
          uniquely fashioned bar. The VIP lounge offers superb services and a
          relaxing ambience with charming, colorful and executive set-up that
          promises to provide a warm experience to its guests.
        </div>
      </section>
      <section className="bar_section4">
        <div className="bar_section4_img"></div>
        <div className="bar_box">
          <div className="bar_text6">
            Restaurant is opened from 8:00 AM -9:00 PM
          </div>
          <div className="bar_text6">
            We offer Local cuisines from a standard kitchen
          </div>
          <div className="bar_text6">₦ 10,000 - ₦ 40,000</div>
          <div className="bar_text6">
            Breakfast service from 8:00 AM - 9:00 AM
          </div>

          <button className="bar_but">View our menu</button>
        </div>
      </section>
      <section className="bar_section5">
        <div className="bar_section5_flex">
          <div className="bar_section5_text1">Hydrate.Relax Be Entertained</div>
          <div className="bar_section5_text2">
            <p>
              Enjoy the very best of local dishes and cuisines in a comfortable
              and relaxed atmosphere. Our restaurant is open all day, greeting
              visitors with the aroma that's
            </p>
            <p>
              inviting. Carefully prepared with unique concepts and styles,
              there's no better place to be than under the bright and peaceful
              lounge/lobby where a fabulous dining experience awaits you.
            </p>
          </div>
        </div>
      </section>
      <section className="bar_section6">
        <img className="b1" src="./images/b1.png" alt="b1" />
        <img className="b2" src="./images/b2.png" alt="b2" />
      </section>
      <Footer />
    </div>
  );
}
