import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Team() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <section className="team_section1"></section>

      <section className="team_section2">
        <div>
          <div className="team_text1">Founder/CEO</div>
          <div className="team_text2">Folarin Bakare</div>
          <div className="team_text3">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus
            tellus, nam dui sapien molestie vulputate rhoncus fermentum. Sit
            cursus ultrices et pellentesque iaculis pellentesque viverra
            facilisis. Neque viverra donec sed sed quisque. Viverra metus nisl
            pulvinar aliquet blandit pulvinar{" "}
          </div>
        </div>
        <img className="teamimg" src="../images/fola.png" alt="fola" />
      </section>
      <section className="team_section3">
        <div>
          <img className="teamimg1" src="./images/segun.png" alt="segun" />
          <div className="team_text4">Osundina Olusegun</div>
          <div className="team_text5">Hotel Manager</div>
          <div className="team_text6">segunosundina@bakersuites.io</div>
        </div>

        <div>
          <img className="teamimg1" src="./images/segun.png" alt="segun" />
          <div className="team_text4">Osundina Olusegun</div>
          <div className="team_text5">Reservations Manager</div>
          <div className="team_text6">segunosundina@bakersuites.io</div>
        </div>

        <div>
          <img className="teamimg1" src="./images/segun.png" alt="segun" />
          <div className="team_text4">Osundina Olusegun</div>
          <div className="team_text5">Executive Chef</div>
          <div className="team_text6">segunosundina@bakersuites.io</div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
