import { Route, Routes, BrowserRouter } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import { EventProvider } from "./context/context";
import "react-notifications/lib/notifications.css";
import "./App.css";
import "./bem/header.css";
import "./bem/footer.css";
import "./bem/home.css";
import "./bem/menu.css";
import "./bem/room.css";
import "./bem/bar.css";
import "./bem/rooms.css";
import "./bem/contact.css";
import "./bem/team.css";
import "./bem/not.css";
import "./bem/policy.css";
import "./bem/gallery.css";

import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Room from "./pages/Room";
import Bar from "./pages/Bar";
import Rooms from "./pages/Rooms";
import Team from "./pages/Team";
import Shortlet from "./pages/Shortlet";
import Contact from "./pages/Contact";
import Policy from "./pages/Policy";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <EventProvider>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/gallery" element={<Gallery />} />
          <Route exact path="/room" element={<Room />} />
          <Route exact path="/rooms" element={<Rooms />} />
          <Route exact path="/bar" element={<Bar />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/shortlet" element={<Shortlet />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/policy" element={<Policy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="*" element={<NotFound />} />
        </Routes>
        <NotificationContainer />
      </BrowserRouter>
    </EventProvider>
  );
}

export default App;
