import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Test() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="body">
      <Header />
      <section className="home_section2">
        <div className="home_section2_inner">
          <img className="img1" src="./images/h1.png" alt="img1" />
          <div className="bbb">
            <div className="headline_1">
              <div className="headline_1_text">01</div>
              <hr className="hr" />
              <div className="headline_1_text">About </div>
            </div>
            <div className="home_text1">Welcome to orai sweet spot</div>
            <div className="home_text2">
              The Bakerverse Suites is Located at Orai town, Benin City, Nigeria
              with various leisure hubs within and around it, the hotel is
              easily accessible from the popular Oluku bypass and a few minutes
              from Iyowa. It Is also a 20-25 minutes drive from famous Federal
              University, UNIBEN.
              <br />
              <br />
              Deluxe, tranquility and grandeur are the key elements of the hotel
              design. The opulent facilities have been equally designed for a
              glamorous tourist, traveler or a family vacation.
              <br />
              <br />
              Whether you are in the city of Benin for business or leisure, you
              will find convenience and satisfaction when you visit Bakerverse
              Suites.
              <br />
              <br />
            </div>
          </div>
        </div>
        <div className="home_section2_inner">
          <div className="bbb">
            <div className="headline_1">
              <div className="headline_1_text">02</div>
              <hr className="hr" />
              <div className="headline_1_text">Dive In </div>
            </div>
            <div className="home_text1">Rooms & Cloud 9</div>
            <div className="home_text2">
              Relax on plush quality beds topped with luxury linens, or get
              creative with our generous workspaces available.
              <br />
              <br />
              Enjoy a soothing bath or get energized in the walk-in shower to
              start your day. Elegant design of the rooms, furniture and
              floor-to-ceiling windows provide the perfect mix of comfort and
              relaxation, enough to put you in a cloud 9.
            </div>
          </div>
          <img className="img1" src="./images/h2.png" alt="img1" />
        </div>
      </section>
      <section className="home_section3">
        <div className="home_box">
          <img className="home_img1" src="./images/img2.png" alt="img2" />
          <div className="home_text3">
            {" "}
            <hr className="text_hr" />
            <div>Wonderland (Room 101)</div>
          </div>
        </div>
        <div className="home_box">
          <img className="home_img2" src="./images/img3.png" alt="img3" />
          <div className="home_text3">
            <hr className="text_hr" />
            <div>Nifty Dune (Room 103)</div>
          </div>
        </div>
        <div className="home_box">
          <img className="home_img3" src="./images/img4.png" alt="img4" />
          <div className="home_text3">
            <hr className="text_hr" />
            <div>Terraville (Room 206)</div>
          </div>
        </div>
        <div className="home_box">
          <img className="home_img4" src="./images/img5.png" alt="img4" />
          <div className="home_text3">
            <hr className="text_hr" />
            <div>Terraville (Room 206)</div>
          </div>
        </div>
      </section>

      <section className="home_section4">
        <div className="home_section4_inner">
          <img className="s42" src="./images/h3.png" alt="img2" />
          <div className="s41">
            <div className="headline_1">
              <div className="headline_1_text">03</div>
              <hr className="hr" />
              <div className="headline_1_text">Cravings</div>
            </div>
            <div className="home_text1">Restaurant & bar</div>

            <Link to="/bar">
              <button className="home_button1">Explore restaurant</button>
            </Link>

            {/*   <div className="home_section4_flex">
              <img
                className="img_section4"
                src="./images/img6.png"
                alt="img5"
              />
              <img
                className="img_section4"
                src="./images/img7.png"
                alt="img6"
              />
              <img
                className="img_section4"
                src="./images/img8.png"
                alt="img7"
              />
            </div> */}
          </div>
        </div>
      </section>

      <section className="home_section5">
        <div className="home_section4_inner">
          <img className="himg4" src="./images/h4.png" alt="h4" />
          <div className="hdiv">
            <div className="headline_2">
              <div className="headline_1_text">04</div>
              <hr className="hr" />
              <div className="headline_1_text">First Class Experience</div>
            </div>
            <div className="home_text4">Amenities</div>

            <div className="home_section5_flex">
              <div>
                <div className="home_text5">Indoor Bar & Restaurant </div>
                <div className="home_text5">Pool Bar</div>
                <div className="home_text5">Local Cuisines</div>
                <div className="home_text5">Executive Lounge</div>
              </div>
              <div>
                <div className="home_text5">Karaoke</div>
                <div className="home_text5">Swimming Pool</div>
                <div className="home_text5">24/7 Electricity</div>
                <div className="home_text5">Free WiFi</div>
              </div>
              {/*  <div>
                <img src="./images/img9.png" alt="img8" className="img8" />
              </div> */}
            </div>
          </div>
        </div>

        <div className="home_section4_inner2">
          <img className="himg51" src="./images/h6.png" alt="h4" />
          <img className="himg5" src="./images/h5.png" alt="h4" />
        </div>
      </section>

      <section className="home_section6">
        <div className="home_section6_inner">
          <div className="home_text6">
            For bookings or inquiries, our team will be happy to help
          </div>

          <Link to="/contact">
            <button className="section6_button">Contact Us</button>
          </Link>
        </div>
      </section>

      <section className="home_section7">
        <div className="headline_3">
          <div className="headline_1_text">05</div>
          <hr className="hr" />
          <div className="headline_1_text">Stay connected with us</div>
        </div>
        <div className="social">
          <a
            href="//www.instagram.com/bakerversesuites"
            target="_blank"
            rel="noreferrer"
          >
            <div className="home_text7">Instagram</div>
          </a>
          <a
            href="//web.facebook.com/bakerversesuites"
            target="_blank"
            rel="noreferrer"
          >
            <div className="home_text7">Facebook</div>
          </a>
        </div>
      </section>
      <Footer />
    </div>
  );
}
